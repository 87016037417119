<template>
  <v-col
    v-if="create"
    :xl="col.xl"
    :lg="col.lg"
    :md="col.md"
    :sm="col.sm"
    :xs="col.xs"
    :cols="col.cols || 12"
    class="d-print-block px-md-2 px-1"
  >
    <!-- readonly模式 或是 橫向填寫模式 -->
    <template v-if="horizon || readonly">
      <v-row>
        <v-col cols="4">
          <div class="d-flex align-center">
            <!-- <span class="red--text" v-if="required">
              *
            </span> -->
            <span>{{ label }}: </span>
          </div>
        </v-col>
        <v-col cols="8">
          <component
            ref="form-data-component"
            :class="itemClass"
            :is="component"
            :formKey="formKey"
            :blockIndex="blockIndex"
            :blockReadonly="blockReadonly"
            :blockDisabled="blockDisabled"
            :formDataItemKey="formDataItemKey"
            :formDataItem="formDataItem"
          ></component>
        </v-col>
      </v-row>
    </template>

    <!-- 填寫模式 -->
    <template v-else>
      <div class="subtitle-1 d-flex">
        <div v-if="showFormDataTitle" class="mr-4 d-flex align-center">
          <span class="red--text" v-if="required"> * </span>
          <span>{{ label }}</span>
        </div>

        <v-btn v-if="copyable" @click="copy" icon>
          <v-icon>fa fa-copy</v-icon>
        </v-btn>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="uploadOnPaste"
              @click="clipboardPhotoUploadActive"
              v-on="on"
              icon
            >
              <v-icon>fa fa-clipboard</v-icon>
              <clipboardPhotoUploader
                v-if="uploadOnPaste"
                @appendPhoto="appendPhoto"
                :multiple="multiple"
                ref="clipboardPhotoUploader"
              ></clipboardPhotoUploader>
            </v-btn>
          </template>

          <span>{{ "upload_clipboard" | t }}</span>
        </v-tooltip>

        <!-- 自訂title右側元件 -->
        <component
          :is="titleSlot"
          class="ml-6 my-1"
          :formKey="formKey"
        ></component>
      </div>

      <formDataHelp
        :formKey="formKey"
        :formDataItem="formDataItem"
      ></formDataHelp>

      <component
        ref="form-data-component"
        :class="itemClass"
        :is="component"
        :formKey="formKey"
        :blockIndex="blockIndex"
        :blockReadonly="blockReadonly"
        :blockDisabled="blockDisabled"
        :formDataItemKey="formDataItemKey"
        :formDataItem="formDataItem"
      ></component>
    </template>
  </v-col>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    formKey: String,
    blockIndex: Number,
    formDataItemKey: String,
    formDataItem: Object,
    blockReadonly: Boolean,
    blockDisabled: Boolean,
  },
  methods: {
    copy() {
      const formDataComponent = this.$refs['form-data-component']
      if(!formDataComponent) return
      if(typeof formDataComponent.copyData != 'function') return
      formDataComponent.copyData()
    },
    clipboardPhotoUploadActive() {
      this.$refs.clipboardPhotoUploader.active()
    },
    appendPhoto(photo) {
      if(!this.$refs['form-data-component']) return
      if(typeof this.$refs['form-data-component'].appendPhoto != 'function') return
      this.$refs['form-data-component'].appendPhoto(photo)
    },
  },
  computed: {
    titleSlot() {
      return this.formDataItem.titleSlot
    },
    formConfig() {
      return this.$store.getters[`form/${this.formKey}/config`]
    },
    formData() {
      return this.$store.getters[`form/${this.formKey}/data`]
    },
    required() {
      if(!this.formDataItem.required) return null
      if(typeof this.formDataItem.required != 'function') {
        return this.formDataItem.required === true
      }
      return this.formDataItem.required(this.formInfo)
    },
    formInfo() {
      return this.$store.getters[`form/${this.formKey}/info`]
    },
    create() {
      if(this.formDataItem.create === undefined) return true
      if(typeof this.formDataItem.create != 'function') {
        return this.formDataItem.create
      }
      return this.formDataItem.create(this.formInfo)
    },
    copyable() {
      if(this.formDataItem.copyable === undefined) return false
      if(typeof this.formDataItem.copyable != 'function') {
        return this.formDataItem.copyable
      }
      return this.formDataItem.copyable(this.formInfo)
    },
    uploadOnPaste() {
      return this.formDataItem.uploadOnPaste === true
    },
    multiple() {
      return this.formDataItem.uploadOnPasteMultiple === true
    },
    label() {
      if(!this.formDataItem.label) return null
      if(typeof this.formDataItem.label != 'function') {
        return this.$t(this.formDataItem.label)
      }
      return this.$t(this.formDataItem.label(this.formInfo))
    },
    type() {
      return this.formDataItem.type
    },
    component() {
      const componentName = `form-item-${this.type}`

      // list in form
      if(this.type === 'list-component') {
        return 'form-item-list-component'
      }

      if(this.formDataItem.component) return this.formDataItem.component
      if(this.$options.components[componentName]) {
        return this.$options.components[componentName]
      }
      return 'form-item-text'
    },
    defaultGrid() {
      if(this.formConfig.defaultGrid) {
        return this.formConfig.defaultGrid
      }
      return {
        xl: 6,
        lg: 6,
        md: 6,
        sm: 12,
        xs: 12,
      }
    },
    col() {
      return {
        ...this.defaultGrid,
        ...this.formDataItem.grid,
      }
    },
    readonly() {
      if(this.blockReadonly) return true
      return this.formDataItem.readonly
    },
    horizon() {
      return this.formDataItem.horizon
    },
    itemClass() {
      if(typeof this.formDataItem.itemClass === 'function') {
        return this.formDataItem.itemClass(this.formInfo)
      }
      return this.formDataItem.itemClass
    },
    showFormDataTitle() {
      const res = ['liff-text', 'liff-selection'].includes(this.type)
      return !res
    },
  },
  components: {
    formDataHelp: () => import('@/components/form/formDataHelp.vue'),
    clipboardPhotoUploader: () => import('@/components/clipboardPhotoUploader/clipboardPhotoUploader.vue'),
    'form-item-text': () => import('@/components/form/formItemTypes/text.vue'),
    'form-item-liff-text': () => import('@/components/form/formItemTypes/liffText.vue'),
    'form-item-textarea': () => import('@/components/form/formItemTypes/textarea.vue'),
    'form-item-liff-textarea': () => import('@/components/form/formItemTypes/liffTextarea.vue'),
    'form-item-html': () => import('@/components/form/formItemTypes/html.vue'),
    'form-item-switch': () => import('@/components/form/formItemTypes/switch.vue'),
    'form-item-selection': () => import('@/components/form/formItemTypes/selection.vue'),
    'form-item-liff-selection': () => import('@/components/form/formItemTypes/liffSelection.vue'),
    'form-item-time': () => import('@/components/form/formItemTypes/time.vue'),
    'form-item-photo': () => import('@/components/form/formItemTypes/photo.vue'),
    'form-item-file': () => import('@/components/form/formItemTypes/file.vue'),
    'form-item-banner': () => import('@/components/form/formItemTypes/banner.vue'),
    'form-item-item-list': () => import('@/components/form/formItemTypes/itemList.vue'),
    'form-item-site-admin-group': () => import('@/components/form/formItemTypes/siteAdminGroup.vue'),
    'form-item-list-component': () => import('@/components/form/formItemTypes/listComponent.vue'),
    'form-item-offset': () => import('@/components/form/formItemTypes/offset.vue'),
    'form-item-readonly-content': () => import('@/components/form/formItemTypes/readonlyContent.vue'),
    'form-item-data-list': () => import('@/components/form/formItemTypes/dataList.vue'),
  },
}
</script>

<style lang="sass" type="text/sass">
</style>
